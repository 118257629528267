<template>
  <q-page padding>
    <q-card flat class="justify-center creative">
      <q-card-section>
        <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">{{ $t('creatives_api') }}</q-toolbar-title>
        <q-breadcrumbs class="q-mr-auto q-ma-sm">
          <q-breadcrumbs-el :label="$t('dashboard')" to="/ " />
          <q-breadcrumbs-el :label="$t('creatives_api')" exact />
        </q-breadcrumbs>
      </q-card-section>
      <q-separator spaced />
      <q-card-section class="row q-col-gutter-md">
        <div class="col-6">
          <q-input
            outlined
            @input="retrieveAffiliateData"
            type="number"
            v-model="affiliateData.id"
            :label="$t('affiliate_id')"
          >
          </q-input>
        </div>

        <div class="col-6">
          <q-input outlined readonly type="text" v-model="affiliateData.token" label="Token">
            <template v-slot:append>
              <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(affiliateData.token)">
                <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                  Copiar Token
                </q-tooltip>
              </q-btn>
            </template>
          </q-input>
        </div>

        <div class="col-6">
          <q-select
            :disable="!wasAffiliateSelected"
            v-model="channel"
            outlined
            clearable
            option-value="id"
            option-label="name"
            :label="$t('select_channel')"
            @clear="(val) => (channel = null)"
            :options="affiliateChannels"
          />
        </div>

        <div class="col-6">
          <q-input outlined readonly type="text" v-model="channelID" :label="$t('channel_id')"> </q-input>
        </div>

        <div class="col-6">
          <q-select
            v-model="display"
            :options="optionsDisplay"
            :label="$t('exhibition_mode')"
            :rules="[isRequired]"
            outlined
          />
        </div>

        <div class="col-6">
          <q-select
            v-model="creativeType"
            :options="creativeTypesOptions"
            option-label="name"
            :label="$t('creative_type')"
            :rules="[isRequired]"
            outlined
          />
        </div>

        <div class="col-12">
          <q-input outlined readonly type="text" :value="getApiLink" :label="$t('creatives_api_url')">
            <template v-slot:append>
              <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(getApiLink)">
                <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                  Copiar API
                </q-tooltip>
              </q-btn>
            </template>
          </q-input>
        </div>
      </q-card-section>
    </q-card>
  </q-page>
</template>

<script>
const dataLink = process.env.VUE_APP_DATA_API;
const apiPath = 'feedcriativos';

import affiliateService from '../../../services/AffiliateService';
import AffiliateMixin from '../../../mixins/AffiliateMixin';

export default {
  mixins: [affiliateService, AffiliateMixin],
  name: 'CreativeApiPage',
  data() {
    return {
      optionsDisplay: ['CSV', 'JSON'],
      creativeTypesOptions: [
        { value: 0, name: 'Todos' },
        { value: 1, name: 'Cupom' },
        { value: 2, name: 'Cupom e Ofertas' },
        { value: 5, name: 'Banner' }
      ],
      affiliateData: {
        email: null,
        id: null,
        token: null
      },
      affiliateChannels: [],
      wasAffiliateSelected: false,
      apiDataUrl: dataLink,
      channel: null,
      channelID: null,
      display: null,
      creativeType: null,
      login: null
    };
  },
  watch: {
    channel() {
      this.selectChannel(this.channel);
    }
  },
  computed: {
    getProfiles: {
      get() {
        return this.$store.state.Account.user;
      }
    },
    getApiLink: {
      get() {
        if (this.channelID && this.display && this.creativeType) {
          return `${this.apiDataUrl}/${apiPath}?token=${this.affiliateData.token}&mode=${this.display}&canalid=${this.channelID}&affid=${this.affiliateData.id}&type=${this.creativeType.value}`;
        }

        return null;
      }
    }
  },
  methods: {
    selectChannel(value) {
      this.channelID = value ? value.id : null;
    },

    async setupAffiliateData(id) {
      this.onLoading(true);
      try {
        const { data, status } = await this.getAffiliateData(id);
        if (status === 200) {
          this.affiliateData = data;
          this.wasAffiliateSelected = true;
          this.channel = null;
          this.setupAffiliateChannels(id);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async setupAffiliateChannels(id) {
      this.onLoading(true);
      try {
        const { data, status } = await this.getAffiliateSiteActiveListByID(id);
        if (status == 200) {
          this.affiliateChannels = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    retrieveAffiliateData(id) {
      if (id) {
        this.setupAffiliateData(id);
      } else {
        this.affiliateChannels = [];
        this.wasAffiliateSelected = false;
        this.channel = null;
        this.affiliateData = {
          email: null,
          id: null,
          token: null
        };
      }
    }
  }
};
</script>

<style></style>
